
/* (1) You can always add it to app/assets/javascripts with require_tree . in you application.js */

/* (2) Or put it in a pack like this and expose it to your view by adding it to a global namespace */

window.calledFromPacks = calledFromPacks;

function calledFromPacks(msg) {
  console.log(msg);
}

/* (3) Or put it in a pack like this and expose it as a behavior using JQuery or ECMA, as below */

import $ from 'jquery';

function some_behavior(msg) {
  console.log(msg);
}

/* JQuery Version */
$(document).ready(function() {
  $('button#jquery-behavior-button').on(
    'click',
    function() {
      some_behavior('JQuery behavior');
    }
  );
});

/* ECMA Script version */
$(() =>
  $('button#ecma-behavior-button').on('click', () => some_behavior('ECMA Script behavior'))
);


