
window.showSmartFeedback = showSmartFeedback;

function showSmartFeedback(group_id) {

  // get the question group
  this_question = document.forms[group_id].elements[group_id];

  // create feedback from selected item feedback
  var feedback = "";
  var button_text = ""
  for (i = 0; i < this_question.length; i++) {
    if (this_question[i].checked) {
      fb = this_question[i].value.split('|');
      if (fb[0] == "CORRECT") {
        button_text = "That's right.";
      } else {
        button_text = "Try again?";
      }
      feedback = feedback + fb[1] + " ";
    }
  }

  // change value of feedback target
  var target = "#" + group_id + "-feedback-target";
  $(target).html(feedback);

  // change value of submit button
  var submit_button = group_id + "-feedback-button";
  if (document.getElementById(submit_button) && button_text.length) {
    document.getElementById(submit_button).value = button_text;
    if (button_text == "That's right.") {
      document.getElementById(submit_button).setAttribute("class", "btn btn-success my-2 my-sm-0");
    } else {
      document.getElementById(submit_button).setAttribute("class", "btn btn-danger my-2 my-sm-0");
    }
  } else {
    //console.log("did not find submit button: " + submit_button);
  }


}

window.mySeeFunction = mySeeFunction;

  function mySeeFunction(mySeeDIV="mySeeDIV",showmoreSee="showmoreSee") {
    var x = document.getElementById(mySeeDIV);
    var y = document.getElementById(showmoreSee);

    if (x.style.display === "none") {
      x.style.display = "block";
      y.innerHTML = "Hide";
    } else {
      x.style.display = "none";
      y.innerHTML = "Suggested Answer";
    }
  }

window.myThinkFunction = myThinkFunction;

  function myThinkFunction(myThinkDIV="myThinkDIV",showmoreThink="showmoreThink") {
    var x = document.getElementById(myThinkDIV);
    var y = document.getElementById(showmoreThink);

    if (x.style.display === "none") {
      x.style.display = "block";
      y.innerHTML = "Hide";
    } else {
      x.style.display = "none";
      y.innerHTML = "Suggested Answer";
    }
  }

window.myDoFunction = myDoFunction;

  function myDoFunction(myDoDIV="myDoDIV",showmoreDo="showmoreDo") {
    var x = document.getElementById(myDoDIV);
    var y = document.getElementById(showmoreDo);

    if (x.style.display === "none") {
      x.style.display = "block";
      y.innerHTML = "Hide";
    } else {
      x.style.display = "none";
      y.innerHTML = "Suggested Answer";
    }
  }



