
/* These are the messages that get popped up for alerts and notices */

window.snackbarMsg = snackbarMsg;
window.snackbarNoticeMsg = snackbarNoticeMsg;
window.snackbarAlertMsg = snackbarAlertMsg;

function snackbarMsg(msg) {
  if (msg) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    $('#snackbar').html(msg);

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
}

function snackbarNoticeMsg(msg) {
  if (msg) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar_notice");
    $('#snackbar_notice').html(msg);

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
}

function snackbarAlertMsg(msg) {
  if (msg) {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar_alert");
    $('#snackbar_alert').html(msg);

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    }
}