  // https://www.w3schools.com/howto/howto_js_off-canvas.asp

  window.openNavFileCC = openNavFileCC;

  function openNavFileCC(slug) {
    document.getElementById("mySidenav").style.width = "95%";
    document.getElementById("mySidenav").classList.add('sidenav-open');
    document.getElementById("close-button").style.display = "none";
    document.getElementById("close-window").style.display = "none";

    $.ajax(
    {
      url: "/courses/get_contents_from_file",
      data: {slug: slug}
    }).done(
      function(html_from_server)
      {
        $('#mySidenav-contents').html(html_from_server);

        $('img[data-zoomable]').addClass('img-zoomable').click(function(){
          var src = $(this).attr('src');
          var modal;
          function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }

          modal = $('<div>').css({
              background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
              backgroundSize: 'contain',
              width:'100%', height:'100%',
              position:'fixed',
              zIndex:'10000',
              top:'0', left:'0',
              cursor: 'zoom-out'
          }).click(function(){
              removeModal();
          }).appendTo('body');
          //handling ESC
          $('body').on('keyup.modal-close', function(e){
            if(e.key==='Escape'){ removeModal(); }
          });
        });
      }
    );
  }


  window.openNavFile = openNavFile;

  function openNavFile(slug) {
    document.getElementById("mySidenav").style.width = "95%";
    document.getElementById("mySidenav").classList.add('sidenav-open');
    document.getElementById("close-button").classList.add('closebtn');

    $.ajax(
    {
      url: "/courses/get_contents_from_file",
      data: {slug: slug}
    }).done(
      function(html_from_server)
      {
        $('#mySidenav-contents').html(html_from_server);

        $('img[data-zoomable]').addClass('img-zoomable').click(function(){
          var src = $(this).attr('src');
          var modal;
          function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }

          modal = $('<div>').css({
              background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
              backgroundSize: 'contain',
              width:'100%', height:'100%',
              position:'fixed',
              zIndex:'10000',
              top:'0', left:'0',
              cursor: 'zoom-out'
          }).click(function(){
              removeModal();
          }).appendTo('body');
          //handling ESC
          $('body').on('keyup.modal-close', function(e){
            if(e.key==='Escape'){ removeModal(); }
          });
        });


        $('[data-toggle="tooltip"]').tooltip({

        });
        $('[data-toggle="popover"]').popover({

        });

      }
    );
  }


  window.openNavPartial = openNavPartial;

  function openNavPartial(slug) {

    document.getElementById("mySidenav").style.width = "95%";
    document.getElementById("mySidenav").classList.add('sidenav-open');
    document.getElementById("close-button").classList.add('closebtn');

    $.ajax(
    {
      url: "/courses/get_contents_from_partial",
      data: {slug: slug}
    }).done(
      function(html_from_server)
      {
        $('#mySidenav-contents').html(html_from_server);
      }
    );
  }


  // https://www.w3schools.com/howto/howto_js_off-canvas.asp

  window.toggleTOC = toggleTOC;

  function toggleTOC()
  {
    if ($('#mySidenav').hasClass('sidenav-open')) {
      closeTOC();
    }
    else {
      openTOC();
    }
  }

  window.openTOC = openTOC;

  function openTOC() {
    var to_be_moved = document.getElementById("course-tree");
    if (!to_be_moved) {
      to_be_moved = document.getElementById("course_tree");
    }
    var target = document.getElementById("slideout-target");
    target.appendChild(to_be_moved);

    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("mySidenav").classList.add('sidenav-open');
    //document.getElementById("close-button").classList.add('closebtn');

    $('#open-toc').html('Close TOC');
  }

  window.closeTOC = closeTOC;

  function closeTOC() {
    var to_be_moved = document.getElementById("course-tree");
    if (!to_be_moved) {
      to_be_moved = document.getElementById("course_tree");
    }
    var target = document.getElementById("inner-left-tree");
    target.appendChild(to_be_moved);

    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").classList.remove('sidenav-open');
    //document.getElementById("close-button").classList.remove('closebtn');

    $('#open-toc').html('Open TOC');
  }

window.openNavFile2 = openNavFile2;
function openNavFile2(slug) {

  document.getElementById("mySidenav").style.width = "95%";
  document.getElementById("mySidenav").classList.add('sidenav-open');
  document.getElementById("close-button").classList.add('closebtn');

  $.ajax(
  {
    url: "/courses/get_contents_from_file",
    data: {slug: slug}
  }).done(
    function(html_from_server)
    {
      $('#mySidenav-contents').html(html_from_server);

      $('img[data-zoomable]').addClass('img-zoomable').click(function(){
        var src = $(this).attr('src');
        var modal;
        function removeModal(){ modal.remove(); $('body').off('keyup.modal-close'); }
        modal = $('<div>').css({
            background: 'RGBA(0,0,0,1.0) url('+src+') no-repeat center',
            backgroundSize: 'contain',
            width:'100%', height:'100%',
            position:'fixed',
            zIndex:'10000',
            top:'0', left:'0',
            cursor: 'zoom-out'
        }).click(function(){
            removeModal();
        }).appendTo('body');
        //handling ESC
        $('body').on('keyup.modal-close', function(e){
          if(e.key==='Escape'){ removeModal(); }
        });
      });
    }
  );
  //document.getElementById("main").style.marginLeft = "95%";
}

window.openNavPartial2 = openNavPartial2;

function openNavPartial2(slug) {
  //console.log("openNavPartial called.");

  document.getElementById("mySidenav").style.width = "95%";
  document.getElementById("mySidenav").classList.add('sidenav-open');
  document.getElementById("close-button").classList.add('closebtn');

  $.ajax(
  {
    url: "/courses/get_contents_from_partial",
    data: {slug: slug}
  }).done(
    function(html_from_server)
    {
      $('#mySidenav-contents').html(html_from_server);
    }
  );
  //document.getElementById("main").style.marginLeft = "95%";
}

//window.closeNav = closeNav;
//function closeNav() {
//  document.getElementById("mySidenav").style.width = "0";
//  document.getElementById("mySidenav").classList.remove('sidenav-open');
//  document.getElementById("close-button").classList.remove('closebtn');
//  $('#mySidenav-contents').html("");
  //document.getElementById("main").style.marginLeft= "0";
//}




window.closeNav = closeNav;
function closeNav() {
  document.getElementById("mySidenav").style.width = "0";
  document.getElementById("mySidenav").classList.remove('sidenav-open');
  document.getElementById("close-button").classList.remove('closebtn');
  $('#mySidenav-contents').html("");
  //document.getElementById("main").style.marginLeft= "0";
}
